<template>
  <div>
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      inline
      label-width="0"
      size="small"
    >
      <el-form-item label=" " prop="platformSiteShop">
        <el-cascader
          ref="platformSiteShop"
          v-model="form.platformSiteShop"
          :options="platformSiteShopOptions"
          :placeholder="$t('page.PlatformSiteShop')"
          filterable
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.style"
          clearable
          collapse-tags
          filterable
          multiple
          placeholder="Style"
        >
          <el-option
            v-for="item in styleOptions"
            :key="item.styleId"
            :label="item.style"
            :value="item.styleId"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          ref="sku-select"
          v-model="form.sku"
          :popper-class="hasSKUQuery ? '' : 'sku-select-dropdown'"
          allow-create
          class="sku-select"
          clearable
          default-first-option
          filterable
          multiple
          placeholder="SKU"
        >
          <el-option
            v-for="item in skuOptions"
            :key="item.id"
            :label="item.sku"
            :value="item.sku"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="platSku">
        <el-input
          v-model="form.platSku"
          clearable
          placeholder="Plat Sku"
          @change="handleQuery"
        />
      </el-form-item>
      <el-form-item
        v-if="virtualWarehouseOptions.length"
        label=" "
        prop="virtualHouseId"
      >
        <el-select
          v-model="form.virtualHouseId"
          :placeholder="$t('page.PlatformVirtualWarehouse')"
          clearable
          filterable
        >
          <el-option
            v-for="item in virtualWarehouseOptions"
            :key="item.id"
            :label="item.virtualName"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button :loading="queryLoading" type="primary" @click="handleQuery">
          {{ $t("page.search") }}
        </el-button>
        <el-button @click="handleReset">
          {{ $t("page.reset") }}
        </el-button>
      </el-form-item>
    </el-form>
    <vxe-toolbar>
      <template #buttons>
        <el-button type="primary" @click="handleOneClickPush">
          查询结果一键推送
        </el-button>
      </template>
    </vxe-toolbar>
    <vxe-table
      ref="table"
      :data="tableData"
      :loading="queryLoading"
      :max-height="maxHeight"
      :scroll-x="{ enabled: false }"
      :scroll-y="{ enabled: false }"
      :show-overflow="false"
      align="center"
      border
      resizable
    >
      <vxe-column field="style" fixed="left" title="Style" />
      <vxe-column field="color" fixed="left" title="Color" />
      <vxe-column field="size" fixed="left" title="Size" />
      <vxe-column field="sku" fixed="left" show-overflow title="SKU" />
      <vxe-column field="platSku" fixed="left" show-overflow title="MSKU" />
      <!--平台可售库存-->
      <vxe-column
        :title="$t('page.AvailableStock')"
        field="availableInventory"
        fixed="left"
      />
      <!--履约仓总库存-->
      <vxe-column
        :title="$t('page.TotalInventoryOfComplianceWarehouse')"
        field="warehouseStock"
        fixed="left"
      />
      <vxe-column v-for="item in columns" :key="item" :title="item">
        <template v-slot="{ row }">
          <div v-if="row.everyWarehouseStock">
            {{ row.everyWarehouseStock[item] }}
          </div>
        </template>
      </vxe-column>
      <!--可推送库存-->
      <vxe-column
        :title="$t('page.PushableInventory')"
        field="pushStockNum"
        fixed="right"
        width="160"
      />
      <vxe-column :title="$t('page.operate')" fixed="right">
        <template v-slot="{ row }">
          <el-button
            size="small"
            type="text"
            @click="handleOpenLogDialog(row)"
          >{{ $t("page.PushLog") }}</el-button>
          <el-button
            size="small"
            type="text"
            @click="handleOpenManualPushDialog(row)"
          >{{ $t("page.ViewPushRules") }}
          </el-button>
        </template>
      </vxe-column>
    </vxe-table>
    <paging ref="pager" :pager="pager" end @update="handlePagerUpdate" />
    <manual-push-dialog
      :platform-site-shop-options="platformSiteShopOptions"
      :row="currentRow"
      :visible.sync="manualPushDialogVisible"
      @refresh="handleQuery"
    />
    <push-log-dialog
      :row.sync="currentRow"
      :visible.sync="pushLogDialogVisible"
    />
  </div>
</template>

<script>
import { getSKUList } from '@/api/select'
import Paging from '@/components/Paging/index.vue'
import { debounceGetTableMaxHeight } from '@/utils'
import ManualPushDialog from './ManualPushDialog.vue'
import PushLogDialog from './PushLogDialog.vue'
import {
  getPlatformSiteShopByUserId,
  getSellableInventoryList,
  getStyleList,
  oneClickPush
} from './apis/list'
import { getVirtualWarehouseList } from '@/views/inventory-visualization/apis'

export default {
  name: 'SellableInventoryList',
  components: {
    PushLogDialog,
    Paging,
    ManualPushDialog
  },
  data() {
    return {
      hasSKUQuery: false,
      platformSiteShopOptions: [],
      styleOptions: [],
      skuOptions: [],
      virtualWarehouseOptions: [],
      form: {
        platformSiteShop: [],
        style: [],
        sku: [],
        platSku: '',
        virtualHouseId: ''
      },
      rules: {
        platformSiteShop: [
          {
            required: true,
            message: this.$t('page.selectPlaceholder'),
            trigger: 'change'
          }
        ],
        virtualHouseId: [
          {
            required: true,
            message: this.$t('page.selectPlaceholder'),
            trigger: 'change'
          }
        ]
      },
      queryLoading: false,
      maxHeight: 0,
      columns: [],
      tableData: [],
      currentRow: null,
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      manualPushDialogVisible: false,
      pushLogDialogVisible: false
    }
  },
  computed: {
    styleParams() {
      const [platformId, siteId, shopId] = this.form.platformSiteShop || []
      return {
        platformId: platformId ? [platformId] : [],
        siteId: siteId ? [siteId] : [],
        shopId: shopId ? [shopId] : []
      }
    },
    queryParams() {
      const selected = this.$refs.platformSiteShop.getCheckedNodes()?.[0]
      const [plat, site, shop] = selected?.pathLabels || []
      return {
        ...this.form,
        platformSiteShop: undefined,
        plat,
        site,
        shop,
        style: this.form.style
          .map(
            (e) => this.styleOptions.find((item) => +item.styleId === +e)?.style
          )
          .filter((e) => e),
        currPage: this.pager.current,
        pageSize: this.pager.size
      }
    }
  },
  watch: {
    form: {
      handler() {
        this.queryChange = true
      },
      deep: true
    },
    'form.platformSiteShop': {
      handler() {
        this.form.virtualHouseId = ''
        this.$nextTick(() => {
          Promise.all([this.getStyleList(), this.getVirtualWarehouseOptions()])
        })
      },
      deep: true
    },
    'form.style': {
      handler() {
        this.getSKUList()
      },
      deep: true
    }
  },
  created() {
    Promise.all([this.getPlatformSiteShopOptions(), this.getStyleList()])
    this.debounceGetTableMaxHeight = debounceGetTableMaxHeight.bind(this)
  },
  mounted() {
    this.debounceGetTableMaxHeight()
    this.createSkuWatch()
    window.addEventListener('resize', this.debounceGetTableMaxHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.debounceGetTableMaxHeight)
  },
  methods: {
    createSkuWatch() {
      this.$watch(
        () => this.$refs['sku-select'].query,
        (val) => {
          this.hasSKUQuery = !!val
        }
      )
    },
    async getPlatformSiteShopOptions() {
      const { datas } = await getPlatformSiteShopByUserId()
      this.platformSiteShopOptions = datas || []
    },
    async getStyleList() {
      if (
        !this.styleParams.platformId.length ||
        !this.styleParams.siteId.length ||
        !this.styleParams.shopId.length
      ) {
        this.styleList = []
        return
      }
      const { datas } = await getStyleList(this.styleParams)
      this.styleOptions = datas || []
    },
    async getSKUList() {
      if (!this.form.style.length) {
        this.skuOptions = []
        return
      }
      const { datas } = await getSKUList({
        styleIdList: this.form.style
      })
      this.skuOptions = datas || []
    },
    async getVirtualWarehouseOptions() {
      const { plat, site, shop } = this.queryParams
      const { datas } = await getVirtualWarehouseList({
        plat,
        site,
        shop
      })
      this.virtualWarehouseOptions = datas || []
    },
    handleQuery() {
      this.$refs.form.validate(async(valid) => {
        if (valid) {
          this.queryLoading = true
          if (this.controller) {
            this.controller.abort()
            setTimeout(() => {
              this.queryLoading = true
            })
          }
          this.controller = new AbortController()
          const signal = this.controller.signal
          if (this.queryChange) {
            this.pager.current = 1
          }
          const { datas } = await getSellableInventoryList(
            this.queryParams,
            signal
          ).finally(() => {
            this.queryLoading = this.queryChange = false
          })
          this.tableData = datas?.list || []
          this.columns = datas?.column || []
          this.pager.total = datas?.totalCount || 0
          this.queryLoading = false
        }
      })
    },
    handleReset() {
      this.form = {
        platformSiteShop: [],
        styleId: [],
        sku: [],
        virtualHouseId: ''
      }
      this.$refs.form.clearValidate()
    },
    handleOneClickPush() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$confirm(
            '本操作将会推送一次查询出的所有SKU的库存，是否确认？',
            '提示',
            {
              closeOnClickModal: false,
              type: 'warning',
              beforeClose: async(action, instance, done) => {
                if (action === 'confirm') {
                  instance.confirmButtonLoading =
                    instance.cancelButtonLoading = true
                  const { msg } = await oneClickPush(this.queryParams).finally(
                    () => {
                      instance.cancelButtonLoading =
                        instance.confirmButtonLoading = false
                    }
                  )
                  this.$message.success(msg)
                  this.handleQuery()
                  done()
                } else {
                  done()
                }
              }
            }
          )
        }
      })
    },
    handleOpenLogDialog(row) {
      this.currentRow = row
      this.pushLogDialogVisible = true
    },
    handleOpenManualPushDialog(row = null) {
      this.currentRow = row
      this.manualPushDialogVisible = true
    },
    handlePagerUpdate(val) {
      this.pager = val
      this.handleQuery()
    }
  }
}
</script>

<style lang="scss" scoped>
.sku-select {
  ::v-deep.el-icon-arrow-up {
    display: none;
  }
}
</style>

<style lang="scss">
.sku-select-dropdown {
  display: none;
}
</style>
