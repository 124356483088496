<template>
  <el-dialog
    :before-close="handleClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :title="$t('page.PushRules')"
    :visible.sync="visible"
    center
    top="5vh"
    width="700px"
  >
    <!--商品信息-->
    <el-descriptions :title="$t('order.Productinformation')" />
    <el-form ref="form" :model="form" label-width="auto" size="small">
      <el-row>
        <el-col :span="12">
          <el-form-item :label="$t('page.PlatformSiteShop')" prop="platformSiteShop">
            <el-input
              v-model="form.platformSiteShop"
              :placeholder="$t('page.selectPlaceholder')"
              disabled
              filterable
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="SKU">
            <el-select
              v-model="form.sku"
              :placeholder="$t('page.selectPlaceholder')"
              clearable
              disabled
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item v-if="row && row.virtualHouseId" :label="$t('page.PlatformVirtualWarehouse')">
            <el-select
              v-model="form.virtualHouseId"
              :placeholder="$t('page.selectPlaceholder')"
              clearable
              disabled
              filterable
            >
              <el-option
                v-for="item in virtualWarehouseOptions"
                :key="item.id"
                :label="item.virtualName"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!--推送规则-->
    <el-descriptions :title="$t('page.PushRules')" />
    <vxe-table
      ref="table"
      :data="tableData"
      :loading="queryLoading"
      :scroll-x="{ enabled: false }"
      :scroll-y="{ enabled: false }"
      :show-overflow="false"
      align="center"
      border
      max-height="400"
    >
      <!--履约仓库-->
      <vxe-column :title="$t('page.ComplianceWarehouse')" field="warehouse" />
      <!--履约仓库存量-->
      <vxe-column :title="$t('page.StockOfComplianceWarehouse')">
        <template v-slot="{row}">
          <vxe-table :data="row.ruleList" :show-header="false" align="center" border="inner">
            <vxe-column field="stockRule" />
          </vxe-table>
        </template>
      </vxe-column>
      <!--推送库存量-->
      <vxe-column :title="$t('page.PushStockLevels')">
        <template v-slot="{row}">
          <vxe-table :data="row.ruleList" :show-header="false" align="center" border="inner">
            <vxe-column field="stockNum" />
          </vxe-table>
        </template>
      </vxe-column>
    </vxe-table>
  </el-dialog>
</template>

<script>
import { debounceGetTableMaxHeight } from '@/utils'
import { getRules } from './apis/manualPushDialog'
import { getVirtualWarehouseList } from '@/views/inventory-visualization/apis'

export default {
  name: 'ManualPushDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      virtualWarehouseOptions: [],
      form: {
        platformSiteShop: '',
        sku: '',
        virtualHouseId: ''
      },
      queryLoading: false,
      tableData: []
    }
  },
  computed: {
    queryParams() {
      const { plat, site, shop } = this.row
      return {
        ...this.form,
        platformSiteShop: undefined,
        plat,
        site,
        shop
      }
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.debonceGetTableMaxHeight()
        if (this.row) {
          const { plat, site, shop } = this.row
          this.form = {
            ...this.row,
            platformSiteShop: `${plat}/${site}/${shop}`
          }
          this.getVirtualWarehouseOptions()
          this.getRules()
        }
      }
    },
    'form.platformSiteShop'() {
      this.$nextTick(async() => {
        await this.getVirtualWarehouseOptions()
      })
    }
  },
  created() {
    this.debonceGetTableMaxHeight = debounceGetTableMaxHeight.bind(this, {
      minHeight: 300,
      offsetBottom: 100
    })
  },
  methods: {
    async getVirtualWarehouseOptions() {
      const { plat, site, shop } = this.queryParams
      const { datas } = await getVirtualWarehouseList({
        plat,
        site,
        shop
      })
      this.virtualWarehouseOptions = datas || []
    },
    async getRules() {
      this.queryLoading = true
      const { datas } = await getRules(this.queryParams).finally(() => {
        this.queryLoading = false
      })
      this.tableData = datas || []
    },
    handleClose() {
      this.form = {
        platformSiteShop: '',
        sku: '',
        virtualHouseId: ''
      }
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style scoped>

</style>
