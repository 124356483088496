<template>
  <el-dialog
    :before-close="handleClose"
    :close-on-press-escape="false"
    :title="$t('page.PushLog')"
    :visible.sync="visible"
    center
    top="5vh"
  >
    <el-descriptions :title="$t('order.Productinformation')" />
    <el-form label-width="auto" size="small">
      <el-row>
        <el-col :span="12">
          <el-form-item :label="$t('page.PlatformSiteShop')" prop="platformSiteShop">
            <el-input v-model="form.platformSiteShop" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="SKU">
            <el-input v-model="form.sku" disabled />
          </el-form-item>
        </el-col>
        <el-col v-if="form.virtualHouseId" :span="12">
          <el-form-item :label="$t('page.PlatformVirtualWarehouse')">
            <el-select v-model="form.virtualHouseId" disabled>
              <el-option
                v-for="item in virtualWarehouseOptions"
                :key="item.id"
                :label="item.virtualName"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-descriptions :title="$t('page.PushRecords')" />
    <vxe-table
      ref="table"
      :data="tableData"
      :loading="queryLoading"
      :max-height="maxHeight"
      :scroll-x="{ enabled: false }"
      :scroll-y="{ enabled: false }"
      :show-overflow="false"
      align="center"
      border
    >
      <vxe-column :title="$t('page.No')" type="seq" width="80" />
      <vxe-column :title="$t('page.PushStockLevels')" field="uploadNum" />
      <vxe-column :title="$t('page.status')" field="status" />
      <vxe-column :title="$t('page.PushTime')" field="pushTime" width="160" />
    </vxe-table>
  </el-dialog>
</template>

<script>
import { debounceGetTableMaxHeight } from '@/utils'
import { getPushLog } from './apis/pushLogDialog'
import { getVirtualWarehouseList } from '@/views/inventory-visualization/apis'

export default {
  name: 'PushLogDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      virtualWarehouseOptions: [],
      form: {
        platformSiteShop: '',
        sku: '',
        virtualHouseId: ''
      },
      tableData: [],
      queryLoading: false,
      maxHeight: 0
    }
  },
  watch: {
    visible: {
      async handler(val) {
        if (val && this.row) {
          const { plat, site, shop } = this.row
          const { datas } = await getVirtualWarehouseList({
            plat,
            site,
            shop
          })
          this.virtualWarehouseOptions = datas || []
          this.form = {
            ...this.row,
            platformSiteShop: `${plat}/${site}/${shop}`
          }
          this.debonceGetTableMaxHeight()
          await this.getPushLog()
        }
      } }
  },
  created() {
    this.debonceGetTableMaxHeight = debounceGetTableMaxHeight.bind(this, {
      minHeight: 300
    })
  },
  methods: {
    async getPushLog() {
      this.queryLoading = true
      const { datas } = await getPushLog(this.row?.productId).finally(() => {
        this.queryLoading = false
      })
      this.tableData = datas || []
    },
    handleClose() {
      this.form = {
        platformSiteShop: '',
        sku: '',
        virtualHouseId: ''
      }
      this.tableData = []
      this.$emit('update:row', null)
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style scoped>

</style>
